<template>
  <div id="order-monitor">
    <dv-scroll-board ref="dvScrollBoardRef" :config="config" class="dv-scr-board" :key="refreshKey"></dv-scroll-board>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "OrderList",
  props: ['data'],
  data() {
    return {
      config: {
        header: ['订单编号', '金额', '店铺', '时间'],
        data: [],
        rowNum: 45, //表格行数
        headerHeight: 40,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        columnWidth: [160],
        align: ['center'],
        waitTime: 200000000,
        refreshKey: 0
      },
    }
  },
  computed: {
    tableData() {
      return this.data.map(item => {
        return [item.orderSN, item.totalAmount, item.shopName]
      })
    }
  },
  watch: {
    data: {
      handler: function (val) {
        const orderData = val.map(item => {
          return [item.orderSN, item.totalAmount, item.shopName, dayjs(item.createTime*1000).format('HH:mm:ss')]
        })
        this.config.data = orderData
        this.refreshKey++ //这一行无用, 可以随时删掉
        try {
          this.$refs['dvScrollBoardRef'].updateRows(orderData, 0)
        } catch (e) {
          console.log(e)
        }
      },
      deep: true,
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
$box-height: 900px;
$box-width: 100%;
#order-monitor {
  width: $box-width;
  //height: $box-height;
  padding: 20px 16px 16px 16px;
  .dv-scr-board {
    width: $box-width;
    height: $box-height;
  }
}
</style>