<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">大数据可视化平台</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-left ml-3">
              <span class="text">数据分析2</span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">云森科技</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <div>
            <dv-border-box-12 style="height: 950px">
              <OrderList :data="orderList"></OrderList>
            </dv-border-box-12>
          </div>
          <div>
            <div class="center-box">
              <div class="big-font">
                <span>订单总数: </span><span>{{ orderTotal }}</span>
              </div>
              <div class="big-font">
                <span>订单总金额: </span><span>{{ orderTotalAmount }}</span>
              </div>
            </div>
            <div>
              <dv-border-box-11 title="每小时销量" style="height: 300px">
                <OrderHourSale :orderHourSaleCount="orderHourSale"></OrderHourSale>
              </dv-border-box-11>
            </div>
          </div>
          <div>
            <dv-border-box13>
              <ShopRanking :shopMap="shopMap" :orderTotal="orderTotal" :orderTotalAmount="orderTotalAmount"></ShopRanking>
            </dv-border-box13>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import { GetOrder, GetAllShopInfo, UserInfoList } from '@/api/order.js'

import OrderList from "@/views/OrderList.vue";
import ShopRanking from "@/views/ShopRanking.vue";
import OrderHourSale from "@/views/OrderHourSale.vue";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
      orderList: [],
      shopMap: {},
      userMap: {},
      orderTotal: 0,
      orderTotalAmount: 0,
      getDataTimer: null
    };
  },
  components: {
    OrderList,
    ShopRanking,
    OrderHourSale
  },
  computed: {
    orderHourSale() {
      let hourlyCounts = {};

      this.orderList.forEach(order => {
        // 将日期对象转换为 "YYYY-MM-DD HH" 的格式
        let hour = this.$dayjs(order.createTime*1000).format("YYYY-MM-DD HH")

        // 在 hourlyCounts 对象中增加对应小时的数量
        if (hour in hourlyCounts) {
          hourlyCounts[hour]++;
        } else {
          hourlyCounts[hour] = 1;
        }
      })
      return hourlyCounts
    }
  },
  async created() {
    await this.initData()
  },
  mounted() {
    this.timeFn();
    clearInterval(this.getDataTimer)
    setInterval(() => {
      this.getListData()
    }, 5000)
    this.getListData()
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
    clearInterval(this.getDataTimer)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async initData() {
      const result = await Promise.all([GetAllShopInfo(), UserInfoList()])
      console.log(result[1])
      const userMap = {}
      const userData = result[1].data.map(item => {
        userMap[item.id] = item
        const { id, phone, name, authorityID } = item
        return { id, phone, name, authorityID }
      })

      const shopMap = {}
      const shopData = result[0].data.list.map(item => {
        const newItem = {
          label: item.alias || item.shopName,
          value: item.shopId,
          userId: item.userId,
          userName: userMap[item.userId].name,
          shopName: item.alias || item.shopName
        }
        shopMap[item.shopId] = {
          orderCount: 0,
          orderAmount: 0,
          ...newItem
        }
        return newItem
      })
      console.log(userData, shopData)
      this.shopMap = shopMap
      this.userMap = userMap
    },
    getListData() {
      const req = {}
      // 测试数据
      const startTime = this.$dayjs().startOf('day').unix()
      const endTime = this.$dayjs().endOf('day').unix()
      req.startTime = startTime
      req.endTime = endTime
      GetOrder(req).then((res) => {
        const orderData = res.data.list || []
        // 如果订单数量一样就不进行更新, 避免重新计算, 节省cpu性能
        if (this.orderList.length === orderData.length) {
          return
        }
        const orderCount = orderData.length
        let totalAmount = 0
        this.orderTotal = orderCount
        const orderList = []
        // 需要把shopMap的orderCount和orderAmount清零, 然后重新计算
        const shopMap = JSON.parse(JSON.stringify(this.shopMap))
        for (const key in shopMap) {
          shopMap[key].orderCount = 0
          shopMap[key].orderAmount = 0
        }
        for (const item of orderData) {
          // 价格为0的订单, 后台会忽略零值字段, 会造成结果为 NAN, 这里判断一下, 赋值给0
          if (isNaN(item.totalAmount)) {
            console.log(item)
            item.totalAmount = 0
          }
          totalAmount += item.totalAmount
          item.shopName = this.shopMap[item.shopId].label
          item.userName = this.userMap[this.shopMap[item.shopId].userId].name
          // 统计店铺订单数量, 和店铺订单总金额
          // 如果orderCount存在, 就加1, 如果不存在, 就赋值为1

          if (shopMap[item.shopId]) {
            if (typeof this.shopMap[item.shopId].orderCount !== 'number') {
              shopMap[item.shopId].orderCount = 0
            }
            if (typeof this.shopMap[item.shopId].orderAmount !== 'number') {
              shopMap[item.shopId].orderAmount = 0
            }
            shopMap[item.shopId].orderAmount += item.totalAmount
            shopMap[item.shopId].orderCount += 1
          }
          if (JSON.stringify(this.shopMap) !== JSON.stringify(shopMap)) {
            this.shopMap = shopMap
          }
          orderList.push(item)
        }
        this.orderTotalAmount = totalAmount
        this.orderList = orderData ? orderData.map(value => {
          value.shopName = this.shopMap[value.shopId].label
          return value
        }) : []
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
* {
  box-sizing: border-box;
}
.center-box {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  .big-font {
    margin: 0 auto;
    font-size: 30px;
    color: #ffffff;
    font-weight: 550;
    height: 40px;
  }
}

</style>
