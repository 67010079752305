<template>
  <div class="order-hour-sale-box">
    <div id="order-hour-sale"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "OrderHourSale",
  props: ['orderHourSaleCount'],
  data() {
    return {
    }
  },
  watch: {
    orderHourSaleCount: {
      handler: function () {
        this.$nextTick(() => {
          this.createChart()
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    createChart() {
      const chartDom = document.getElementById('order-hour-sale')
      const myChart = echarts.init(chartDom)
      let hours = [];
      let now = this.$dayjs()
      for (let i = 0; i <= now.hour(); i++) {
        let hour = this.$dayjs().hour(i).format('YYYY-MM-DD HH');
        hours.push(hour);
      }
      const xAxisData = []
      const seriesData = []
      for (const item of hours) {
        xAxisData.push(item.slice(-2))
        const count = this.orderHourSaleCount && this.orderHourSaleCount[item] || 0
        seriesData.push(count)
      }
      const option = {
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ],
        tooltip: {
          trigger: 'axis'   // 配置鼠标移动到折线图上时触发提示框
        }
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-hour-sale-box {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  #order-hour-sale {
    width: 100%;
    height: 300px;
  }
}
</style>