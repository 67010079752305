import request from "@/utils/request";

export const GetOrder = (data) => {
    return request({
        url: '/api/v2/order/list',
        method: 'GET',
        params: data
    })
}

export function GetAllShopInfo(data) {
    return request({
        url: '/api/shop/getAllShopInfo',
        method: 'get',
        params: data
    })
}

export function UserInfoList(data) {
    return request({
        url: '/api/v1/user/getInfoList',
        method: 'get',
        params: data
    })
}