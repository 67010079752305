<template>
  <div id="shop-ranking">
    <dv-scroll-board ref="shopBoardRef" class="dv-scr-board" :config="config"></dv-scroll-board>
  </div>
</template>

<script>
export default {
  name: "ShopRanking",
  props: ['shopMap', 'orderTotal', 'orderTotalAmount'],
  data() {
    return {
      config: {
        header: ['店铺名', '订单数', '销售额', '占比', '用户'],
        data: [],
        rowNum: 600, //表格行数
        headerHeight: 40,
        index: true,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        columnWidth: [50, 90, 70, 80, 70, 80],
        align: ['center']
      },
    }
  },
  watch: {
    shopMap: {
      handler: function (val) {
        let shopData = Object.values(val)
        shopData = shopData.sort((a, b) => {
          return b.orderCount - a.orderCount
        })
        const tempData = shopData.map(item => {
          return [item.shopName, item.orderCount, item.orderAmount, Math.round(item.orderCount / this.orderTotal * 100).toString()+'%', item.userName]
        })
        this.config.data = tempData
        try {
          this.$refs['shopBoardRef'].updateRows(tempData, 0)
        } catch (e) {
          console.log(e)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 900px;
$box-width: 100%;
#shop-ranking {
  width: $box-width;
  //height: $box-height;
  padding: 20px 16px 16px 16px;
  .dv-scr-board {
    //height: $box-height;
    //width: $box-width;
  }
}
</style>